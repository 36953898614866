import { Link } from "gatsby";
import React from "react";

const Footer = ()  => {

  return (
    <footer className="bg-gray-100 p-8">
    <div className="container mx-auto">
      <nav className="sm:flex mb-4 mx-auto max-w-4xl">
        <div className="sm:w-1/4 h-auto">
          <div className="mb-2">Fabriqo</div>
          <div className="text-sm flex flex-col">
            <Link className="mb-1 hover:underline" to="/about">About</Link>
            <Link className="mb-1 hover:underline" to="/legal">Legal</Link>
          </div>
        </div>

        <div className="sm:w-1/4 h-auto sm:mt-0 mt-8">
          <div className="text-blue mb-2">Follow Us</div>
          <div className="inline-flex space-x-4 ...">
            <div className="flex-1 ...">
              <a href="https://www.linkedin.com/company/4818760">
                <i className="fa fa-linkedin" aria-hidden="true" title="Visit our LinkedIn page"></i>
                <span className="sr-only">Visit our LinkedIn page</span>
              </a>
            </div>
            <div className="flex-1 ...">
              <a href="https://www.facebook.com/fabriqo">
                <i className="fa fa-facebook" aria-hidden="true" title="Visit our Facebook page"></i>
                <span className="sr-only">Visit our Facebook page</span>
              </a>
            </div>
            <div className="flex-1 ...">
              <a href="https://twitter.com/fabriqoapp">
                <i className="fa fa-twitter" aria-hidden="true" title="Visit our Twitter page"></i>
                <span className="sr-only">Visit our Twitter page</span>
              </a>
            </div>
            <div className="flex-1 ...">
              <a href="https://github.com/fabriqo">
                <i className="fa fa-github" aria-hidden="true" title="Visit our GitHub page"></i>
                <span className="sr-only">Visit our GitHub page</span>
              </a>
            </div>
          </div>
        </div>

        <div className="sm:w-1/4 h-auto sm:mt-0 mt-8">

        </div>

        <div className="sm:w-1/2 sm:mt-0 mt-8 h-auto">
          <div className="text-red-light mb-2">Newsletter</div>
          <div className="mt-4 flex">
            <form action="//fabriqo.us5.list-manage.com/subscribe/post?u=280179a21586eed141cbdb6fb&amp;id=5a65228202" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate w-full" target="_blank" noValidate="">
              <div className="flex">
                <input type="email" defaultValue="" name="EMAIL" id="mce-EMAIL-ea" className="flex-grow p-2 border border-grey-light round text-grey-dark text-sm h-auto" placeholder="Your email address" required="" />
                <div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true"><input type="text" name="b_280179a21586eed141cbdb6fb_5a65228202" tabIndex="-1" defaultValue=""/></div>
                <input type="submit" value="Sign up" name="subscribe" id="mc-embedded-subscribe" className="bg-blue-300 text-white rounded-sm h-auto text-xs p-3" />
              </div>
            </form>
          </div>
        </div>
      </nav>

      <div className="sm:flex mb-4 mx-auto max-w-4xl">
        <p>© {(new Date()).getFullYear()} Fabriqo Limited</p>
      </div>
    </div>
  </footer>
  );
};

export default Footer;

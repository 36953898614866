import React from "react";
import Footer from "./footer";

import Header from "./header";
import SEO from "./seo";

const defaultKeywords = [`fashion`, `tech`, `open-source`];
const defaultTitle = 'Fabriqo';

const Layout = props => {
  const {
    children,
    keywords = defaultKeywords,
    title = defaultTitle,
  } = props;

  return (
    <>
      <SEO
        keywords={keywords}
        title={title}
      />
      <div className="flex flex-col min-h-screen font-sans text-gray-900">
        <Header />

        <main className="flex-1 w-full max-w-4xl px-4 py-8 mx-auto md:px-8 md:py-16">
          {children}
        </main>

        <Footer />
      </div>
    </>
  );
};

export default Layout;

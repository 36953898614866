import React from "react";

import Layout from "../components/layout";

function AboutPage() {
  return (
    <Layout title="About">
      <section className="flex flex-col items-center md:flex-row">
        <div>
          <h1 className="mb-8 text-4xl">About</h1>

          <p className="mb-4">
            The Fabriqo Platform is built on top of the industry standards set by the Fabriqo Organisation.
          </p>

          <p className="mb-4">
            We aim to be the go to place for anyone interested in getting started building apps for the Fabriqo Block standard.
          </p>

          <p className="mb-4">
            It is also the easiest way to explore what apps and data libraries other members of the community have contributed with, both free and premium.
          </p>
        </div>
      </section>
    </Layout>
  );
}

export default AboutPage;
